<template>
  <div>
    <div class="search-box top-container" style="height: 181px">
      <a-row type="flex" justify="space-between" :gutter="76">
        <a-col :span="7" class="row">
          <span class="label">交易状态:</span>
          <a-select v-model="searchText.tradeState" placeholder="请选择">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in transactOption"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="7" class="row">
          <span class="label">付款意愿:</span>
          <a-select v-model="searchText.ifPay" placeholder="请选择">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in isPayOption"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="7" class="row">
          <span class="label">付款时间:</span>
          <a-range-picker
            v-model="searchText.payTime"
            valueFormat="YYYY-MM-DD"
          />
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between" :gutter="76">
        <a-col :span="7" class="row">
          <span class="label">联系人:</span>
          <a-input v-model="searchText.tradeName" placeholder="请输入" />
        </a-col>
        <a-col :span="7" class="row">
          <span class="label">手机号:</span>
          <a-input v-model="searchText.tradePhone" placeholder="请输入" />
        </a-col>
        <a-col :span="7" class="row">
          <span class="label">用户名:</span>
          <a-input v-model="searchText.tradeUsername" placeholder="请输入" />
        </a-col>
      </a-row>
      <div class="search-item right-btn">
        <a-button @click="resetData" class="button-item"> 重置 </a-button>
        <a-button @click="search" class="button-item"> 查询 </a-button>
      </div>
    </div>
    <div class="bottom-container">
      <a-table
        :columns="columns"
        :pagination="pagination"
        :data-source="data"
        :scroll="{ y: 375 }"
        :loading="loading"
      >
        <template slot="ifPay" slot-scope="text, record">
          {{ record.ifPay === true ? "是" : "否" }}
        </template>
        <template slot="tradeState" slot-scope="text, record">
          {{ transactNumber[record.tradeState] }}
        </template>
        <template slot="transactionId" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              <div class="tip">
                <p>交易单号:{{ record.transactionId }}</p>
                <p>付款时间:{{ record.payTime }}</p>
                <p>支付方式:{{ payTypeNumber[record.payType] }}</p>
                <p>支付金额:{{ record.payAmount }}</p>
                <p>交易状态:{{ transactNumber[record.tradeState] }}</p>
              </div>
            </template>
            <span
              @click="addFactory('edit', record)"
              style="color: rgb(255, 70, 0); cursor: pointer"
            >
              {{ record.transactionId }}
            </span>
          </a-tooltip>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "tradeUsername",
    key: "tradeUsername",
    title: "用户名",
    ellipsis: true,
    scopedSlots: { customRender: "tradeUsername" },
  },
  {
    title: "手机号",
    dataIndex: "tradePhone",
    ellipsis: true,
    key: "tradePhone",
  },
  {
    title: "企业名称",
    dataIndex: "tradeCompany",
    ellipsis: true,
    key: "tradeCompany",
  },
  {
    title: "联系人",
    dataIndex: "tradeName",
    ellipsis: true,
    key: "tradeName",
  },
  {
    title: "付款意愿",
    dataIndex: "ifPay",
    ellipsis: true,
    key: "ifPay",
    scopedSlots: { customRender: "ifPay" },
  },
  {
    title: "申请时间",
    key: "creatOrderTime",
    dataIndex: "creatOrderTime",
    ellipsis: true,
    width: 200,
    scopedSlots: { customRender: "creatOrderTime" },
  },
  {
    title: "付款时间",
    key: "payTime",
    dataIndex: "payTime",
    ellipsis: true,
    width: 200,
    scopedSlots: { customRender: "payTime" },
  },
  {
    title: "交易状态",
    key: "tradeState",
    dataIndex: "tradeState",
    ellipsis: true,
    scopedSlots: { customRender: "tradeState" },
  },
  {
    title: "交易单号",
    key: "transactionId",
    dataIndex: "transactionId",
    ellipsis: true,
    width: 200,
    scopedSlots: { customRender: "transactionId" },
  },
];
import pagination from "@/mixins/Pagination";
import { upgradeApi } from "@/api/upgradeService";
import { setKey } from "@/utils/utils.js";
export default {
  mixins: [pagination],
  data() {
    return {
      columns,
      searchText: {
        tradeName: null, //用户名
        tradePhone: null, //手机号
        tradeUsername: null, //联系人
        ifPay: undefined, //是否付款
        payTime: null, //付款时间
      },
      isPayOption: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      transactOption: [
        { label: "支付成功", value: "SUCCESS" },
        { label: "支付失败", value: "PAYERROR" },
        { label: "未支付", value: "NOTPAY" },
        { label: "已关闭", value: "CLOSED" },
      ],
      transactNumber: {
        SUCCESS: "支付成功",
        PAYERROR: "支付失败",
        NOTPAY: "未支付",
        CLOSED: "已关闭",
      },
      payTypeNumber: {
        JSAPI: "公众号支付",
        NATIVE: "扫码支付",
        APP: "APP支付",
        MICROPAY: "付款码支付",
        MWEB: "H5支付",
        FACEPAY: "刷脸支付",
      },
      loading: false,
      data: [],
    };
  },
  methods: {
    resetData() {
      this.searchText = {
        tradeName: null,
        tradePhone: null,
        tradeUsername: null,
        ifPay: undefined,
        payTime: [],
      };
      this.pagination.current = 1;
      this.getBaseData();
    },
    search() {
      this.pagination.current = 1;
      this.getBaseData();
    },
    async getBaseData() {
      this.loading = true;
      let baseParmase = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        tradeName: this.searchText.tradeName,
        tradePhone: this.searchText.tradePhone,
        tradeUsername: this.searchText.tradeUsername,
        ifPay: this.searchText.ifPay,
        tradeState: this.searchText.tradeState,
        payStartTime: this.searchText.payTime ? this.searchText.payTime[0] : null,
        payEndTime: this.searchText.payTime ? this.searchText.payTime[1] : null,
      };
      try {
        let res = await upgradeApi.getPayRecord(baseParmase);
        if (this.pagination.current > 1 && res.data.records.length == 0) {
          this.pagination.current -= 1;
          this.getBaseData();
        }
        this.pagination.total = res.data.total;
        this.data = setKey(res.data.records);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getBaseData();
    },
    onPage(e) {
      this.pagination.current = e;
      this.getBaseData();
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
<style lang="scss" scoped>
.search-item {
  width: 26%;
  text-align: right;
  .button-item {
    margin-left: 10px;
    &:nth-child(2) {
      background-color: #ff4600;
      color: #fff;
    }
  }
}
.right-btn {
  width: 100%;
}

.ant-table-wrapper {
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}

.row {
  display: flex;
  margin-bottom: 20px;

  .label {
    width: 80px;
    line-height: 34px;
    text-align: left;
    margin-left: 25px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .input {
    width: 100%;
  }

  .ant-select {
    width: 100%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
}
.tip {
  width: 600px;

  p {
    margin: 10px;
  }
}
</style>
