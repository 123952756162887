import axios from "@/config/http";
export const upgradeApi = {
    // 分页查询小程序付费记录
    getPayRecord(params) {
        return axios.get("/mall/serviceUpdate/paymentRecord", { params });
    },
    // 查询历史服务信息
    getPayHistory(params){
        return axios.get("/mall/serviceUpdate/serviceHistory",{params})
    },
    // 更新服务（升级）
    serviceUpdate(data){
        return axios.put("/mall/serviceUpdate/serviceUpdate",data)
    }
}